import * as React from "react"
// import { Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

// import { Container, Row, Col } from "react-bootstrap"

import lighting from "../../images/lighting.jpg"

const SecondPage = () => (
  <Layout>
    <Seo title="Energy Efficiency Installation" />
    <h1>Energy Efficiency Installation</h1>
    <p>California is the most energy efficient state in the nation. Its energy efficiency standards have saved Californians more than $96 billion in reduced electricity bills since 1977. Master electrician Thomas Tripolt of All Electric can help you find ways to make your home or business more energy efficient. After reviewing your current electrical environment, he will make practical recommendations, including LED lighting installation options.</p>
    <h2>LED Lighting</h2>
    <p>As an experienced LED lighting installer, Thomas Tripolt can help you convert your home fixtures and bulbs to low energy LED lighting. LED lighting has come a long way in recent years regarding its reliability, power consumption, light color quality (color temperature) and pricing. LED lighting is much more energy efficient than all of the other options currently available. LEDs do not generate the excess heat that incandescent bulbs or halogen bulbs do, and they don’t have the slow start-up time of fluorescent and compact fluorescent (CFL) bulbs. Unlike fluorescent and compact fluorescent (CFL) bulbs, they don’t contain mercury, so they don’t have to be disposed of as toxic waste.</p>
    <p>Sometimes it is more cost-effective to replace entire fixtures with energy-efficient counterparts that include better insulation. Thomas can look at your current lighting and help you make the right choice.</p>
    <p className="text-center">
      <img src={lighting} alt="home lighting" className="img-fluid" />
    </p>
    <h2>Dimmers</h2>
    <p>Installing dimmer switches automatically saves 90% in electrical usage over a standard on-off switch. When you choose to dim your lights, even more electricity is saved. Dimmers can also extend the life of your low voltage bulbs.</p>
    <h2>Motorized Window Shades</h2>
    <p>Window shades save energy by reducing the load on HVAC systems. When you opt for motorized blinds and shades, you can control the amount of light in your home or business and protect your privacy just by pressing a button. Thomas Tripolt can install your motorized shades.</p>
  </Layout>
)

export default SecondPage
